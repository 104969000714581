import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Reports {
  id: string;
  reportId: string;
  propertysName: string;
  generatedAt: string;
  type: string;
  paymentStatus: PaymentStatus;
}

interface S {
  reportList: Reports[];
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export enum PaymentStatus {
  Paid = "Paid",
  Pending = "Pending",
  NoInvoice = "No Invoice",
}

export default class ReportsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      reportList: [
        {
          id: "1",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "2",
          reportId: "HD81G3",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Pending,
        },
        {
          id: "3",
          reportId: "D782HV",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.NoInvoice,
        },
        {
          id: "4",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "5",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Electricity",
          paymentStatus: PaymentStatus.Paid,
        },
        {
          id: "6",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (cold)",
          paymentStatus: PaymentStatus.NoInvoice,
        },
        {
          id: "7",
          reportId: "KB17H4",
          propertysName: "Abstergo Ltd.",
          generatedAt: "4/12/2023",
          type: "Water (hot)",
          paymentStatus: PaymentStatus.Pending,
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchReports = (search: string) => {
    console.log({ search });
  };

  downloadReport = (id: string) => {
    console.log({ id });
  };

  downloadInvoice = (id: string) => {
    console.log({ id });
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
