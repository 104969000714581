import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Property {
  id: string;
  propertysName: string;
  propertysAddress: string;
  countTenant: number;
  countMeter: number;
}

interface S {
  propertiesList: Property[]
  currentPage:number;
  itemsPerPage:number;
  totalPages:number;
  currentProperties: Property[];
  firstItemIndex:number,
  lastItemIdex:number,
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PropertiesPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      propertiesList: [
        {
          id: "1",
          propertysName: "Bright Horizons Inc.",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "2",
          propertysName: "Wavecrest Realty",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "3",
          propertysName: "Skyline Ventures",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "4",
          propertysName: "Summit Properties",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "5",
          propertysName: "Golden Gate Realty",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "6",
          propertysName: "Green Valley Estates",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "7",
          propertysName: "Lakeside Properties",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "8",
          propertysName: "Evergreen Realty",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "9",
          propertysName: "Maple Leaf Holdings",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "10",
          propertysName: "Sunset Ridge Group",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "11",
          propertysName: "Oceanview Properties",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "12",
          propertysName: "Pine Hill Realty",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "13",
          propertysName: "Sunrise Holdings",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "14",
          propertysName: "Crescent Moon Realty",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "15",
          propertysName: "Horizon Line Estates",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "16",
          propertysName: "Silver Oak Realty",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "17",
          propertysName: "Riverbank Properties",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "18",
          propertysName: "Mountain Peak Estates",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "19",
          propertysName: "Forest Grove Realty",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "20",
          propertysName: "Sunshine Realty Group",
          propertysAddress: "New Hyde Park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
      ],
      currentPage:1,
      itemsPerPage:10,
      totalPages: 0,
      currentProperties: [],
      firstItemIndex:1,
      lastItemIdex:10,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchProperties = (search: string) => {

  }

  filterProperties = () => {

  }

  addProperties = () => {
    this.props.navigation.navigate("AddPropertiesPage");
  }

  addTenant = (id: string) => {

  }

  editProperties = (id: string) => {
    
  }

  deletePropeerties = (id: string) => {
    
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await Promise.all([this.updatePagination()]);
  }

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
    if (    
      prevState.currentPage !== this.state.currentPage ||
      prevState.itemsPerPage !== this.state.itemsPerPage ||
      prevState.propertiesList !== this.state.propertiesList
      ) {
      await Promise.all([ this.updatePagination()]);
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNextPage = () => {
    if (this.state.currentPage < Math.ceil(this.state.propertiesList.length / this.state.itemsPerPage)) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
  };

  handlePrevPage = () => {
    if (this.state.currentPage > 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ currentPage: pageNumber });
  };

  updatePagination = () => {
    const { currentPage, itemsPerPage, propertiesList } = this.state;
    let indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProperties = propertiesList.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(propertiesList.length / itemsPerPage);

    if (indexOfLastItem > propertiesList.length ) {
        indexOfLastItem = propertiesList.length;
    }

    this.setState({
      currentProperties,
      totalPages,
      firstItemIndex:indexOfFirstItem,
      lastItemIdex:indexOfLastItem,
    });
  };
  // Customizable Area End
}
