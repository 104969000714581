import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";
import {
  searchIcon,
  filterIcon,
  addIcon,
  editIcon,
  deleteIcon,
  noPropertieisIcon,
} from "./assets";
// Customizable Area End

import PropertiesPageController, {
  Props,
  configJSON,
} from "./PropertiesPageController";
// Customizable Area Start

// Customizable Area End
export default class PropertiesPage extends PropertiesPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.propertiesContainer}>
        {this.state.propertiesList.length === 0 ? (
          <Box sx={webStyle.noPropertyBlock}>
            <Box sx={webStyle.noPropertyBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noPropertyTitle}>
                {configJSON.noPropertyTitleText}
              </Typography>
              <Typography style={webStyle.noPropertySubTitle}>
                {configJSON.noPropertySubTitleText}
              </Typography>
              <Box sx={webStyle.buttonBlock}>
                <button
                  style={webStyle.addNewButton}
                  data-test-id="submitBtn"
                  type="button"
                  onClick={this.addProperties}
                >
                  <Typography sx={webStyle.buttonText}>
                    {configJSON.addPropertyText}
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.propertiesTableHeader}>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchProperties(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
              </Box>
              <Box sx={webStyle.actionContainer}>
                <button
                  style={webStyle.filterButton}
                  onClick={() => {
                    this.filterProperties();
                  }}
                >
                  <img src={filterIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.filterText}
                  </Typography>
                </button>
                <button
                  style={webStyle.addPropertiesButton}
                  onClick={this.addProperties}
                >
                  <img src={addIcon} alt="" style={webStyle.btnIcon} />
                  <Typography style={webStyle.typographyText}>
                    {configJSON.addPropertyText}
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={webStyle.propertisTable}>
              <Box sx={webStyle.propertisTableHead}>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.propertyNameText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.addressText}
                </Typography>
                <Typography style={webStyle.headerShortTitle}>
                  {configJSON.numberOfTenantsText}
                </Typography>
                <Typography style={webStyle.headerShortTitle}>
                  {configJSON.numberOfMetersText}
                </Typography>
                <Typography style={webStyle.headerTitleAction}>
                  {configJSON.actionsText}
                </Typography>
              </Box>
              {this.state.currentProperties.map((proprty) => (
                <Box sx={webStyle.propertisTableBody} key={proprty.id}>
                  <Box sx={webStyle.propertyItem}>{proprty.propertysName}</Box>
                  <Box sx={webStyle.propertyItem}>
                    {proprty.propertysAddress}
                  </Box>
                  <Box sx={webStyle.propertyShortItem}>
                    {proprty.countTenant}
                  </Box>
                  <Box sx={webStyle.propertyShortItem}>
                    {proprty.countMeter}
                  </Box>
                  <Box sx={webStyle.propertyItemAction}>
                    <button
                      style={webStyle.addTenantButton}
                      onClick={() => this.addTenant(proprty.id)}
                    >
                      <img src={addIcon} alt="" style={webStyle.btnIcon} />
                      <Typography style={webStyle.typographyText}>
                        {configJSON.addTenantText}
                      </Typography>
                    </button>
                    <button
                      style={webStyle.editButton}
                      onClick={() => {
                        this.editProperties(proprty.id);
                      }}
                    >
                      <img src={editIcon} alt="" style={webStyle.btnIcon} />
                      <Typography style={webStyle.typographyText}>
                        {configJSON.editText}
                      </Typography>
                    </button>
                    <button
                      style={webStyle.deleteButton}
                      onClick={() => {
                        this.deletePropeerties(proprty.id);
                      }}
                    >
                      <img src={deleteIcon} alt="" style={webStyle.btnIcon} />
                      <Typography style={webStyle.typographyText}>
                        {configJSON.deleteText}
                      </Typography>
                    </button>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "5%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={() => this.handlePrevPage()}
                  data-test-id="previous-button"
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#F8FAFC",
                    color: "#333",
                    border: "none",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  disabled={this.state.currentPage === 1}
                >
                  {"<"}
                </button>

                {this.state.totalPages <= 3 ? (
                  [...Array(this.state.totalPages).keys()].map((page) => (
                    <button
                      key={page + 1}
                      data-test-id={"page-button"}
                      style={{
                        margin: "0 5px",
                        padding: "5px 10px",
                        backgroundColor:
                          this.state.currentPage === page + 1
                            ? "#E0F7FE"
                            : "#F8FAFC",
                        color:
                          this.state.currentPage === page + 1
                            ? "#1A7BA4"
                            : "#333",
                        border: "none",
                        textDecoration:
                          this.state.currentPage !== page + 1
                            ? "underline"
                            : "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </button>
                  ))
                ) : (
                  <>
                    <button
                      data-test-id="ellipsis"
                      style={{
                        margin: "0 5px",
                        padding: "5px 10px",
                        backgroundColor:
                          this.state.currentPage === 1 ? "#E0F7FE" : "#F8FAFC",
                        color:
                          this.state.currentPage === 1 ? "#1A7BA4" : "#333",
                        border: "none",
                        textDecoration:
                          this.state.currentPage !== 1 ? "underline" : "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handlePageChange(1)}
                    >
                      1
                    </button>

                    {this.state.currentPage > 3 && (
                      <span style={{ margin: "0 5px" }}>...</span>
                    )}

                    {this.state.currentPage > 2 &&
                      this.state.currentPage < this.state.totalPages - 1 && (
                        <>
                          <button
                            data-test-id="middle-page"
                            style={{
                              margin: "0 5px",
                              padding: "5px 10px",
                              backgroundColor: "#F8FAFC",
                              color: "#333",
                              border: "none",
                              textDecoration: "underline",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.handlePageChange(this.state.currentPage - 1)
                            }
                          >
                            {this.state.currentPage - 1}
                          </button>
                          <button
                            data-test-id="middle-page2"
                            style={{
                              margin: "0 5px",
                              padding: "5px 10px",
                              backgroundColor: "#E0F7FE",
                              color: "#1A7BA4",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.handlePageChange(this.state.currentPage)
                            }
                          >
                            {this.state.currentPage}
                          </button>
                          <button
                            data-test-id="middle-page3"
                            style={{
                              margin: "0 5px",
                              padding: "5px 10px",
                              backgroundColor: "#F8FAFC",
                              color: "#333",
                              border: "none",
                              textDecoration: "underline",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.handlePageChange(this.state.currentPage + 1)
                            }
                          >
                            {this.state.currentPage + 1}
                          </button>
                        </>
                      )}

                    {this.state.currentPage < this.state.totalPages - 2 && (
                      <span style={{ margin: "0 5px" }}>...</span>
                    )}

                    <button
                      data-test-id="last-page"
                      style={{
                        margin: "0 5px",
                        padding: "5px 10px",
                        backgroundColor:
                          this.state.currentPage === this.state.totalPages
                            ? "#E0F7FE"
                            : "#F8FAFC",
                        color:
                          this.state.currentPage === this.state.totalPages
                            ? "#1A7BA4"
                            : "#333",
                        border: "none",
                        textDecoration:
                          this.state.currentPage !== this.state.totalPages
                            ? "underline"
                            : "none",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.handlePageChange(this.state.totalPages)
                      }
                    >
                      {this.state.totalPages}
                    </button>
                  </>
                )}

                <button
                  data-test-id="next-page"
                  onClick={() => this.handleNextPage()}
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#F8FAFC",
                    color: "#333",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  disabled={this.state.currentPage === this.state.totalPages}
                >
                  {">"}
                </button>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                <Typography
                  fontFamily={'"Nunito Sans", sans-serif'}
                  fontSize={"12px"}
                  color={"grey"}
                >
                  {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                  {this.state.propertiesList.length} results
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8FAFC",
    padding: "0px 30px",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  filterButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 20px",
    padding: "6px 10px",
  },
  addPropertiesButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 10px",
  },
  headerTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(30% - 80px)",
    margin: "12px 0px",
  },
  headerShortTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(20% - 80px)",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "320px",
    margin: "12px 0px",
  },
  addTenantButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(30% - 80px)",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
  },
  propertyShortItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(20% - 80px)",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "320px",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noPropertyBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noPropertyBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noPropertyTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  noPropertySubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  addNewButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px auto",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "430px",
  },
  buttonText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  buttonBlock: {
    display: "flex",
    flexDirection: "row",
  },
  typographyText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
};
// Customizable Area End
