import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  validate: boolean;
  email:string;
  emailError:string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ForgotPasswordPageController extends BlockComponent<Props, S, SS> {
  forgotPaswordEmailApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.forgotPaswordEmailApiCallId = "";

    this.state = {
      validate: false,
      email:"",
      emailError:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.forgotPaswordEmailApiCallId && responseJson.errors) {
       this.forgotPasswordEmail(responseJson);
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  setValidate = (validate: boolean) => {
    this.setState({ validate });
  };

  resendEmailBtn = () => {
   if (this.state.validate) {
    this.forgotPasswordEmailNotification();
   }
  };

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setFormDate = (formData: any) => {
   this.setState({email:formData,emailError:""})
  };

  forgotPasswordEmailNotification = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestBody = {
        data:
             {
                email: this.state.email
              }
        };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postforgotPasswordEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
  
    this.forgotPaswordEmailApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  forgotPasswordEmail = (responseJson: any) => {
    if (responseJson.errors) {
      const errors = responseJson.errors[0].email;
     this.setState({emailError:errors});
    }
  }
  // Customizable Area End
}
